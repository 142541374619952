import * as React from 'react';
import { Header, Footer } from '@/components/shared/layouts';
import { LayoutProps } from '@/interfaces';
import { ContactPopup } from '..';

const DefaultLayout = ({ children }: LayoutProps) => {
  return (
    <>
      <Header />
      <main>{children}</main>
      <ContactPopup />
      <Footer />
    </>
  );
};

export default DefaultLayout;
